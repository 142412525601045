import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

var exports = {};
var getDay = Date.prototype.getDay;

var tryDateObject = function tryDateGetDayCall(value) {
  try {
    getDay.call(value);
    return true;
  } catch (e) {
    return false;
  }
};

var toStr = Object.prototype.toString;
var dateClass = "[object Date]";

var hasToStringTag = _shams();

exports = function isDateObject(value) {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  return hasToStringTag ? tryDateObject(value) : toStr.call(value) === dateClass;
};

export default exports;